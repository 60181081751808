import "./App.css";

import { RiInstagramLine } from "react-icons/ri";

import image from "./assets/logo.png";

function App() {
  return (
    <>
      <div className="flex w-screen h-screen bg-water-bg z-0"></div>
      <div className="flex z-10 w-full h-full absolute top-0 left-0 bg-sky-50 blur-sm opacity-40"></div>
      <div className="flex flex-col w-screen h-screen absolute top-0 left-0 place-content-center items-center text-white z-20">
        {/* <div className="logo items-center"> */}
        <img className="w-1/3 mb-12" src={image} alt="logo" />
        {/* </div> */}
        <h1 className="text-7xl text-sky-900 text-center uppercase p-5 mb-2">
          coming soon
        </h1>
        <p className="text-lg md:text-2xl text-center text-pink-600 md:w-2/3 w-3/4 mb-6 md:mb-3">
          Hold tight as we get our working robots together and produce an
          exquisite experience!
        </p>
        <a href="https://instagram.com/_pehnaavaa/">
          <RiInstagramLine className="w-12 h-12 md:w-16 md:h-16 my-2 text-sky-900" />
        </a>
        <div className="w-full my-8 md:absolute md:bottom-0 md:left-0 md:my-4 ">
          <p className="text-center text-sky-900">
            &copy; {new Date().getFullYear()} Pehnaavaa.ca
          </p>
        </div>
      </div>
    </>
  );
}

export default App;
